// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-build-index-tsx": () => import("./../src/templates/_Build/index.tsx" /* webpackChunkName: "component---src-templates-build-index-tsx" */),
  "component---src-templates-categories-index-tsx": () => import("./../src/templates/Categories/index.tsx" /* webpackChunkName: "component---src-templates-categories-index-tsx" */),
  "component---src-templates-category-index-tsx": () => import("./../src/templates/Category/index.tsx" /* webpackChunkName: "component---src-templates-category-index-tsx" */),
  "component---src-templates-contact-index-tsx": () => import("./../src/templates/Contact/index.tsx" /* webpackChunkName: "component---src-templates-contact-index-tsx" */),
  "component---src-templates-experience-index-tsx": () => import("./../src/templates/Experience/index.tsx" /* webpackChunkName: "component---src-templates-experience-index-tsx" */),
  "component---src-templates-home-index-tsx": () => import("./../src/templates/Home/index.tsx" /* webpackChunkName: "component---src-templates-home-index-tsx" */),
  "component---src-templates-insta-links-index-tsx": () => import("./../src/templates/InstaLinks/index.tsx" /* webpackChunkName: "component---src-templates-insta-links-index-tsx" */),
  "component---src-templates-post-index-tsx": () => import("./../src/templates/Post/index.tsx" /* webpackChunkName: "component---src-templates-post-index-tsx" */),
  "component---src-templates-posts-index-tsx": () => import("./../src/templates/Posts/index.tsx" /* webpackChunkName: "component---src-templates-posts-index-tsx" */),
  "component---src-templates-search-index-tsx": () => import("./../src/templates/Search/index.tsx" /* webpackChunkName: "component---src-templates-search-index-tsx" */)
}

